@use 'sass:map';

.cmp-accordion {
    --accordion-icon-color: var(--color-cold-600);
    --accordion-item-border-color: var(--color-neutral-400);

    border-top: 2px solid var(--accordion-item-border-color);

    @include breakpoint(tablet) {
        border-width: 1px;
    }

    &__item {
        border-bottom: 2px solid var(--accordion-item-border-color);

        @include breakpoint(tablet) {
            border-width: 1px;
        }
    }

    &__header {}

    &__button {
        background: transparent;
        border: none;
        padding: map.get($spacing, s-16) 0 map.get($spacing, s-16) map.get($spacing, s-48);
        position: relative;

        @include breakpoint(tablet) {
            padding-bottom: map.get($spacing, s-32);
            padding-top: map.get($spacing, s-32);
        }

        &:hover {
            cursor: pointer;
        }

        @include focus {
            border-radius: 2px;
            outline: 2px solid var(--color-cold-600);
        }

        &--expanded {
            .cmp-accordion__icon {
                mask-image: var(--svg-minus);
            }
        }
    }

    &__title {
        @include font-size-per-breakpoint(h5);
        line-height: 1;
        color: #000000; 
    }


    &__icon {
        // background-color: var(--accordion-icon-color);
        background-color: var(--color-cold-500);
        height: rem(16);
        left: 0;
        margin-top: rem(-6);
        mask-image: var(--svg-plus);
        mask-position: center;
        mask-repeat: no-repeat;
        mask-size: contain;
        position: absolute;
        top: 50%;
        transition: mask-image 0.2s ease-in;
        width: rem(16);

        @include breakpoint(tablet) {
            margin-top: rem(-8);
        }

        @include breakpoint(desktop) {
            margin-top: rem(-4);
        }
    }

    &__panel {
        margin: 0 0 map.get($spacing, s-16) 0;

        @include breakpoint(tablet) {
            margin-bottom: map.get($spacing, s-32);
            margin-left: map.get($spacing, s-48);
        }

        .cmp-text {
            color: var(--color-neutral-500);
            max-width: rem(725);

            @include font-size-per-breakpoint(body-m);
            @include list-styled;

            p {
                margin: 0 0 map.get($spacing, s-16);
            }
        }

        &--expanded {}

        &--hidden {}
    }
}